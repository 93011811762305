// Text Block Specific Styles
.pageEl {
  .textBlockElement {
    h3 {
      margin-bottom:.25em;
    }
    table {
      border-style: solid;
      border-color: #999;
      caption {
        text-align: center;
        font-size: 14px;
        padding: 5px;
        font-weight: bold;
      }
      td,
      th {
        padding: 2px 12px;
      }
      &:not([border]) {
        border-style: none;
        border-spacing: 2px;
        border-collapse: separate;
        th {
          @include table-th;
          @include table-th-text;
        }
        td {
          font-size: 12px;
          padding: $table-cell-padding-vertical $table-cell-padding-horizontal;
          height: $table-row-min-height; // Acts as min-height on table cells
          background: #eee;
        }
      }
      &[align="center"] {
        margin-left: auto;
        margin-right: auto;
      }
    }
    blockquote {
      font-size: 1.5em;
      padding: 1em;
      float: none;
      width: 75%;
      margin: auto;
    }

    img {
      width: auto;
      max-width: 100%;
      display: block;
      margin: auto;
    }
    .leftTextImage,
    .originalTextImage,
    .rightTextImage {
      br {
        display: none;
      }
      .cutline {
        width: 100%;
        margin: 0 auto;
      }
    }
    p {
      img {
        height: auto !important;
        max-width: 100%;
      }
    }
    .text {
      > ol,
      > ul {
        list-style-position: inside;
        margin-left: 0;
      }
    }
  }
  &.call-to-action {
    .textBlockElement {
      display: flex;
      flex-direction: column;
      h3 {
        order:1;
        font-weight: 100;
        margin-bottom: 10px;
      }
      div[class*="TextImage"] {
        order:0;
        margin:5px 0px 15px 0px;
        img {
          height: 100px;
        }
      }
      .text {
        order: 1;
        p,a {
          font-family: $fontOne;
          font-size: 16px;
        }
        p {
          margin-bottom: 10px;
          font-weight: 400;
        }
        a {
          font-weight: 600;
          text-decoration: underline;
        }
      }
    }
  }
}

// Text CTA Icons
.text-icons {

  .textBlockElement {

    .leftTextImage,
    .rightTextImage {
      width: 100%;
      height: 60px;
      margin: 0 0 15px 0;

      img { height: 100%; }
      [src*="aes_icon.svg"]{ max-width: 99px; }
      [src*="medal_icon.svg"]{ max-width: 31px; }
      [src*="location_pin.svg"]{ max-width: 29px; }

    }

    .text {
      padding: 0 30px;

      a {
        font-family: $fontOne;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
      }

    }

  }

}

.text-50 {

  .text { padding: 0 20%; }

}
