.tweet-container {
    border: 1px solid #707070;
}

.tweet-container, .tweet-avatar {
    border-radius: 4px;
}

.tweet-container {
    background-color: #FFF;
    color: #333;
}

.tweet-header {
    padding: 10px;
}

.tweet-header .header {
    text-align: center
}

.header-name,.header-screen-name {
    display: block;
    position: relative;
}

.header-name {
    font-size: 12px;
}

.header-screen-name {
    font-size: 16px;
    margin-top: 5px;
}

.header-screen-name:before {
    content: '';
    position: relative;
    border: 1px solid $link-color;
    border-width: 1px 0px 0px 0px;
    width: 70px;
    top: -3px;
    display: block;
    margin: 0px auto;
}

.tweets {
    background-color: #F2F2F2;
    max-height: 250px;
    overflow-y: auto;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
}
$tweetpaddingsides:10px;
.tweet-text {
    padding: 13px $tweetpaddingsides 2px $tweetpaddingsides;
}

.tweet-footer {
    padding: 5px $tweetpaddingsides 5px $tweetpaddingsides;
    background: #eaeaea;
}

.tweet-icons {
    font-size: 11px;
    font-weight: 400;
    list-style-type: none;
}

.tweet-link, .tweet-name:hover, .tweet-hover:hover, .tweet-screen-name, .tweet-date, .tweet-text a, .tweet-text a:hover, .tweet-date:hover, .tweet-url, .tweet-url:hover {
    color: $link-color;
}

.tweet-container a {
    text-decoration: none;
}

.tweet-text a:hover,.tweet-url:hover {
    text-decoration: underline;
}

.tweet-conversation, .tweet-conversation:hover, .tweet-user {
    color: #333;
}

.tweet-icons li {
    display: inline-block;
    cursor: pointer;
}

.tweet-screen-name {
    float: left;
    margin-right: 5px;
}

.tweet {
    border-bottom: 1px solid #DBDBDB;
}

.tweet-text p {
    margin-bottom: 0;
}

.twitter-logo {
    display: none;
}

.tweet-container-footer {
    line-height: 25px;
    text-align: right;
    margin-right: 10px;
}
//
a.tweet-url,
a[href*="t.co"],
a.tweet-date,
a.tweet-link,
.tweet-screen-name,
.tweet-text {
    font-weight: 400;
    font-size: 14px;
}
a.tweet-conversation {
    padding: 10px 0;
    display: block;
}
