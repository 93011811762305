// MODS FOR MOBILE NAV DISPLAY

html.has-mobile-nav {
  .theme-search-wrapper,
  .theme-main-nav,
  .theme-sub-nav,
  #topNavPlaceholder { display: none; }
  #sn-site-logo {
    display: block;
    left: initial;
    margin: 0 auto;
    height: $account-nav-height-mobile;
    border: 0;
    padding: 15px;
    position: relative;
    top: 0;
    z-index: 0;
    box-sizing: border-box;
    .sn-site-logo-background {
      background-position: center center;
    }
  }
}

#topNav {
  position: absolute;
  min-width: 250px;
  left: 0;
  right: 0;
  font-family: $fontOne;
  z-index: 400;

  .collapsed-mobile-nav & { top: $account-nav-height-mobile; }

  // SEARCH BAR

  .has-sub-nav & {

    .theme-search-box {
      z-index: 1;
      top: 173px;
      box-shadow: none;
    }

    .theme-search-box { height: 41px; }

    .theme-search-input,
    .theme-search-input:focus {
      background: #cccccc;
      height: 41px;
    }


  }

  .has-sub-nav .home:not(.newsPage) & {
    .theme-search-box { top: 94px; } //122px; }
  }

  .nav-fixed &,
  .has-sub-nav.nav-fixed .home:not(.newsPage) & {
    .theme-search-box { top: 77px; } // 68px; }
  }

  .has-sub-nav.nav-fixed & {
    .theme-search-box { top: 109px; }
  }
  .theme-search-bar{
    box-sizing: content-box;
    height: $site-tagline-height;
  }
  .theme-search-bar,
  & {
    // box-sizing: content-box;
    // height: $site-tagline-height;

    .theme-search-box {
      position: absolute;
      display: none;
      z-index: -1;
      overflow: hidden;
      height: 38px;
      top: 122px;
      transition: top 300ms;
      right: 0;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 0 5px rgba(0,0,0,.3);

      ~ .theme-search-box { margin-left: $site-search-spacing; }
    }

    .theme-search-input,
    .theme-search-submit,
    .theme-search-clear {
      float: left;
      height: 38px;
      line-height: $site-search-height;
      transition: background $transition-linear;
      width: 100%;
      border-radius: 0 0 3px 3px;
    }

    .theme-search-input,
    .theme-search-clear { font-size: $site-search-input-font-size; }

    .theme-search-input {
      background: $site-search-input-background;
      border: none;
      padding: 0 ($site-search-height - $site-search-border-width * 2) 0 $site-search-spacing;

      &::-ms-clear { display: none; }
      &:focus {
        outline: none;
        background: #ffffff;
      }
    }

    .theme-search-submit {
      cursor: pointer;
      color: $site-search-submit-color;
      font-size: $site-search-submit-font-size;
      border: $site-search-border-width solid $site-search-submit-border-color;
      padding: 0 $site-search-spacing;
      background: $site-search-submit-background;

      &:focus,
      &:hover { background: $site-search-submit-background-focus; }
    }

    .theme-search-clear {
      float: left;
      text-decoration: none;
      text-align: center;
      margin-left: 0 - $site-search-height - $site-search-border-width;
      margin-right: $site-search-border-width;
      width: $site-search-height;

      &:before {
        content: "✕";
        color: #9ca8af;
        font-family: FontAwesome;
        font-size: $site-search-input-font-size;
      }
    }
  }

  .theme-nav-style-slide .theme-nav-menus { border-top: solid 1px rgba(0, 0, 0, 0.1); }

  .theme-mobile-nav {

    .theme-search-box {
      position: relative;
      display: block;
      top: 0;
      z-index: 0;
      border-radius: 0;
      height: auto;
      right: auto;
      box-shadow: none;

      .theme-search-input,
      .theme-search-submit,
      .theme-search-clear {
        border-radius: 0;
        height: 30px;
        line-height: 30px;
      }

      .theme-search-submit { width: 70px; }

      .theme-search-input { width: calc(100% - 70px); }

    }

  }

  // MAIN & SUB NAV SHARED STYLES

  .theme-nav-style-dropdown,
  .theme-nav-dropdown {
    .paywall_image {
      height: .75em;
      margin-right: .1em;
    }
  }

  .theme-nav-style-dropdown {

    /* Level One */
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;

    > .theme-nav-item,
    > .theme-nav-title {
      padding: 0;
      float: left;
      display: block;
      height: 100%;
    }

    > .theme-nav-title a {
      text-transform: none;
      color: $sub-nav-title-color;
      font-weight: 100;
      display: block;
      padding: 0 $sub-nav-title-spacing*.7 0 $sub-nav-title-spacing;
      text-decoration: none;

      &:after {
        content: '\f105';
        font-family: FontAwesome;
        font-size: 1.1em;
        margin-left: $sub-nav-title-spacing;
        font-weight: 100;
      }
    }

    > .theme-nav-item {
      position: relative;
      border-right: $main-nav-item-separator;
      cursor: pointer;

      > .theme-nav-link {
        display: inline-block;
        background-color:rgba(0,0,0,0);
        white-space: nowrap;

        &:hover { text-decoration: none; }
      }
    }

    // DROPDOWNS (mainnav & subnav)

    .theme-nav-more > a:after {
      content: "+";
      margin-left: .3em;
      display: inline-block;
      position: relative;
      top: -.1em;
    }

    .theme-nav-link {
      transition:
        color $transition-linear,
        background-color $transition-linear;
    }

    ~ .theme-nav-dropdown {
      font-size: $dropdown-font-size;
    	display: none;
      width: 100%;
      min-width: $dropdown-width;
    	position: absolute;
    	text-align: left;
    	background: $dropdown-color;
    	border-top: $dropdown-border;
    	z-index: 200;
      transition:
        opacity $transition-linear,
        margin-top $transition-linear;

      &.loading:before {
        content: "Loading...";
        font-size: 10px;
        display: block;
        line-height: 20px;
        opacity: 0.5;
        padding: 0 $dropdown-spacing;
      }

    	> .theme-nav-item {
      	display: table;
    		position: relative;
    		width: 100%;
    		min-height: $dropdown-item-height;
    		border-right: 0;
    		border-bottom: $main-nav-item-separator;

    		&:last-child { border-bottom: none; }

        &.hasChild > .theme-nav-link {
          padding-right: $dropdown-spacing * 2;

          &:before {
            content: "\f0da";
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: $nav-item-before-size / -2;
            line-height: $nav-item-before-size;
            width: $dropdown-spacing + $nav-item-before-size / 2;
            text-align: left;
            opacity: 0.5;
            font-family: FontAwesome;
            font-size: $nav-item-before-size;
            color: $highlight-color;
            transition: width $transition-linear;
          }
        }

        &.dropdown-open > .theme-nav-link:before {
          color: $accent-color;
          width: $dropdown-spacing * 0.2 + $nav-item-before-size;
          opacity: 1;
        }

      	> .theme-nav-link {
          color: $dropdown-text-color;
        	display: table-cell;
          vertical-align: middle;
        	padding: $dropdown-spacing/2 $dropdown-spacing;
          font-weight: 400;
      		line-height: 120%;
      		vertical-align: middle;
          background-color: transparent;
          transition: color 300ms, background-color 300ms;
          font-family: $fontTwo;
      	}


        &.selected > .theme-nav-link,
        &:hover > .theme-nav-link {
          text-decoration: none;
          color: $link-color;
          background-color: $dropdown-selected;
        }

        &.disabled > .theme-nav-link {
          color: $dropdown-text-disabled;

          &:hover { color: $dropdown-text-disabled-hover; }
        }
      }

      &[data-nav-level="2"] { margin-top: -$dropdown-shift-vert; }

      &:not([data-nav-level="2"]) {
        margin-top: -1px;  // ofset for 1px boder
        margin-left: -$dropdown-shift-horiz;
      }

      &.dropdown-align-right:not([data-nav-level="2"]) { margin-left: $dropdown-shift-horiz; }

      &.dropdown-opening {
        display: block;
        opacity: 0;
      }

      &.dropdown-open {
        display: block;
        opacity: 1;

        &[data-nav-level="2"] { margin-top: 0; }
        &:not([data-nav-level="2"]) { margin-left: 0; }
      }
    }
  }

  .theme-search-wrapper {
    border-bottom: $site-tagline-separator;
    color: $site-tagline-text-color;
    background-color: $site-tagline-color;
    overflow: hidden;
    font-family: $fontTwo;
    font-size:em(12);
  }

  .site-tagline-text {
    padding: 0 $site-tagline-spacing;
    display: block;
    overflow: auto;
    white-space: nowrap;
    @include flex(1,1,0);
  }

  .theme-search-bar {
    line-height: $site-tagline-height;
    @include flex-box(row);

    .theme-page-search { @include flex(0); }
  }

  .theme-main-nav {
    position: relative;
    font-size: $main-nav-font-size;
    color: $main-nav-text-color;
    background-color: $main-nav-color;

    .nav-fixed & { border-bottom: solid 1px #eee; }

    .theme-nav {
      display: flex;
      justify-content: flex-end;
      > .theme-nav-item {

        > .theme-nav-link {
          color: $main-nav-text-color;
          text-transform: none;
          padding: ($main-nav-height - 13px - 18px) $main-nav-spacing 23px; // 71px $main-nav-spacing 31px;
          line-height: 18px;
          font-size: 18px;
          font-weight: 400;
          transition: padding 300ms;
          &:after {
            content:'';
            border-bottom: 2px solid transparent;
            display: flex;
            transition: border-color 300ms;
          }
          // &:not(x):hover { background-color: $main-nav-hover-color; } // :not() for specificity over selected state
        }

        &.disabled {
          > .theme-nav-link { color: $main-nav-text-disabled; }
          &:hover > .theme-nav-link { color: $main-nav-text-disabled-hover; }
        }

        &.selected > .theme-nav-link,
        &:hover > .theme-nav-link {
          color:$link-color;
          position: relative;
          &:after{
            content:'';
            border-bottom: 2px solid $link-color;
            display: flex;
          }
        }
      }
    }
  }

  .theme-sub-nav {
    position: relative;
    font-size: $sub-nav-font-size;

    .home:not(.newsPage) & { display: none !important; }

    background-color: $sub-nav-color;
    .theme-nav {
      line-height: $sub-nav-height;
      > .theme-nav-item.disabled {
        > .theme-nav-link { color: $sub-nav-text-disabled; }
        &:hover > .theme-nav-link { color: $sub-nav-text-disabled-hover; }
      }

      > .theme-nav-item {

        > .theme-nav-link {
          color: $sub-nav-text-color;
          text-transform: none;
          padding: 0 $sub-nav-spacing;
          font-weight: 100;
          &:before{
            content: '';
            border-bottom: 2px solid transparent;
            position: absolute;
            width: calc(100% - 20px);
            height: 2px;
            left: 10px;
            bottom: 10%;
            transition: border-color 300ms;
          }
        }
        &.selected > .theme-nav-link,
        &:hover > .theme-nav-link {
          &:before{
            border-bottom: 2px solid #ffffff;
          }
        }
      }

    }

    &:empty { display: none; }
  }

  .theme-search-wrapper,
  .theme-main-nav,
  .theme-sub-nav {
    width: 100%;
    margin: 0 auto;
  }

  .theme-search-bar,
  .theme-nav-style-dropdown {
    position: relative;
    margin: auto;
    width: 100%;
    max-width: $maxWidth;
    transition:
      max-width $transition-linear,
      left $transition-linear,
      height $transition-linear,
      line-height $transition-linear;
    @extend %clearfix;
  }

  &.has-site-logo {
    .theme-search-bar,
    .theme-nav-style-dropdown {
      max-width: $maxWidth;
      left: 0;
    }
    .theme-sub-nav .theme-nav-style-dropdown {
      max-width: $maxWidth;
      left: 0px;
    }
    &.nav-fixed {
      .theme-sub-nav .theme-nav-style-dropdown {
        left: 0px;
      }
    }
  }

}

// @media only screen and (max-width:1275px){
  // #topNav.has-site-logo .theme-search-bar,
  .has-main-nav #topNav.has-site-logo .theme-main-nav .theme-nav-style-dropdown {
    // max-width: 1024px;
    padding: 0 15px 0 ($site-logo-width + 15px);
    box-sizing: border-box;
  }
// }

// 3. Navigation and Logo
#topNav.nav-fixed {
  top: 0;
  position: fixed;

  .theme-search-bar {
    line-height: $site-tagline-height-fixed;
    height: $site-tagline-height-fixed;
  }

  .theme-main-nav {
    .theme-nav {
      line-height: $main-nav-height-fixed;
      .theme-nav-item .theme-nav-link {
        // padding: ($main-nav-height-fixed - 11px - 18px) $main-nav-spacing 11px;
        padding: 31px $main-nav-spacing 25px; //11px;
      }
    }

    .theme-nav-style-dropdown > .theme-nav-item.hasChild > .theme-nav-link:before { height: 8px; }
  }

  .theme-sub-nav {
    .theme-nav { line-height: $sub-nav-height-fixed; }
  }

}

// Placeholder styles for when top nav goes fixed. If there is no sub nav and no
// banner graphic, this fills in the gap and visually blends with the page content.

#topNavPlaceholder {
  border-bottom: $sub-nav-height solid #fff;
  max-width: $maxWidth;
  margin: auto;
  height: $nav-placeholder-height-no-subnav;
  transition: height $transition-linear;

  &.page-has-banner { border-color: transparent; }
  .has-sub-nav & { height: $nav-placeholder-height; }
  .home:not(.newsPage) & { height: $nav-placeholder-height-no-subnav; }

}

#topNav.nav-fixed + #topNavPlaceholder {
  height: $nav-placeholder-height-no-subnav-fixed;
  .has-sub-nav & { height: $nav-placeholder-height-fixed; }
  .home:not(.newsPage) & { height: $nav-placeholder-height-no-subnav-fixed; }
}

// If subnav is not present, the site banner should slide up under the site logo.
html.has-main-nav:not(.has-sub-nav) {
  #siteHeader { margin-top: -$sub-nav-height; }
  #topNav.nav-fixed ~ #siteHeader { margin-top: -$sub-nav-height-fixed; }
}

.search-on {

  .toggle-search {
    // width: 60px;
    z-index: 1;
    transition: bottom $transition-standard;
    order: 9000;
    position: relative;
    padding: 23px 15px 23px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: flex-end;

    &:after {
      content: '\f002';
      display: flex; // inline-block;
      position: relative; // absolute;
      font-family: FontAwesome;
      // right: 0;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #fff;
      background-color: #888888;
      border-radius: 100px;
      // bottom: 28px;
      transition: background-color $transition-standard;
      transition: transform .2s ease-in-out;
      flex-flow: row;
      justify-content: center;
      align-items: center;
    }

    &:hover,
    &.search-box-initiated {
      cursor: pointer;

      &:after {
        background-color: $accent-color-darken-5;
        transform: scale(1.1);
      }

    }

  }

  .nav-fixed & {

    // .toggle-search:after { bottom: 15px; }

  }

}
#topNav .site-tagline-text{
  opacity: 0;
}
