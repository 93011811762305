.mediaSlider {
  margin:0px 0px;
  > h2:empty,
  > p:empty {
    display: none;
  }
  .flexslider {
    border: none;
  }
}
.sn-media-slider .slider .slide-title {
  font-size: 48px;
  font-family: $fontOne;
  font-weight: bold;
}
.sn-media-slider .slider .slide-description {
  font-size: 20px;
  font-weight: 400;
  @media only screen and (max-width: 1024px){
    font-size: 14px;
    line-height: normal;
  }
}
.sn-media-slider .slider.sn-breakpoint-lt-500 .slide-description {
  @media only screen and (max-width: 1024px){
    font-size: 13px;
    line-height: normal;
  }
}
.sn-media-slider {
  .slider {
    .slide-description,
    .slide-title {
      text-shadow: none;
      margin:0;
    }
    // paging styles
    .slider-pagination {
      padding: 0 8%;
      @media only screen and (max-width: 768px){ display: none; }
      .paging-item {
        background-color: #ffffff;
        border:2px solid #fff;
        transition: background-color 300ms;
        &:hover,
        &.flex-active {
          background-color: $link-color;
        }
      }
    }
    &.bottom-left-paging {
      .slider-pagination,
      .slider-pagination-numbers {
        bottom:10%;
      }
      .slides {
        .slide {
          .slide-overlay {
            padding: 5% 8% 8% 8%;
          }
        }
      }
    }
  }
  .slides {
    .slide {
      .slide-overlay {
        padding: 5%;
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.1+0,0.2+100 */
        background: -moz-linear-gradient(top, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.3) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.3) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#33000000',GradientType=0 ); /* IE6-9 */
      }
    }
  }
  .flex-direction-nav a {
    opacity: 1;
    width: 50px;
    &:before {
      font-size: 30px;
      text-shadow: none;
      width: 50px;
      text-align: center;
      background-color:transparent;
      border-radius:100%;
      transition: background-color 300ms;
    }
  }
  .flexslider:hover .flex-direction-nav a {
    opacity: 1;
    &:before {
      background-color: rgba(0,0,0,.25);
    }
  }
  .flexslider:hover .flex-direction-nav .flex-next,
  .flex-direction-nav .flex-next {
    right: 20px;
  }
  .flexslider:hover .flex-direction-nav .flex-prev,
  .flex-direction-nav .flex-prev {
    left: 20px;
  }
  .flexslider:hover .flex-direction-nav .flex-next,
  .flex-direction-nav .flex-next,
  .flexslider:hover .flex-direction-nav .flex-prev,
  .flex-direction-nav .flex-prev {

  }
}
.sn-media-slider .slider.top-left-paging,
.sn-media-slider .slider.top-center-paging,
.sn-media-slider .slider.top-right-paging,
.sn-media-slider .slider.bottom-left-paging,
.sn-media-slider .slider.bottom-center-paging,
.sn-media-slider .slider.bottom-right-paging {
  margin: 0;
}

@media only screen and (max-width: 1024px){
  .flex-direction-nav { display: none; }
}
